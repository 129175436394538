.search-bar-container {
  padding: 1rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-result {
  padding: 10px 20px;
}

.search-result:hover {
  background-color: #0d6efd;
  color: #f8f8f8;
}

.results__cont {
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
  position: absolute;
  z-index: 5;
  top: 70px;
  margin: 0 1rem 0 1rem;
}